import axios from 'axios';

const OPENAI_API_URL = 'https://api.openai.com/v1/chat/completions';
const PERPLEXITY_API_URL = 'https://api.perplexity.ai/chat/completions';
const CLAUDE_WORKER_URL = 'https://blue-mud-fd74.stuart-884.workers.dev';

const generateLinkedInPost = async (businessInfo, coreTopic, keywords, language, isClaudeMode) => {
  console.log('Generating LinkedIn Post with:', {
    businessInfo,
    coreTopic,
    keywords,
    language,
    isClaudeMode
  });

  const systemMessage = `
  You are a professional LinkedIn content creator with 15+ years of experience in crafting high-impact, original posts that stand out. Every post should feel fresh, innovative, and uniquely tailored to the topic, capturing attention and sparking conversation. 

  **Objective**:
  Create a LinkedIn post that resonates with professionals interested in the core topic. Emphasize originality and encourage engagement through unique insights, authentic examples, and high-value takeaways.

  **Core Topic**: ${coreTopic}
  
  **Contextual Business Information**: ${businessInfo}

  **Keywords to Incorporate**: ${keywords}
  
  **Language**: ${language}

  ### Instructions for Generating the Post:

  1. **Opening Hook**: 
      - Your task is to create a **completely original** and **attention-grabbing** opening line.
      - Avoid repeating common phrases or directly copying any example wording provided here. Instead, focus on deriving new phrasing that genuinely reflects the topic, sparking curiosity or challenging assumptions.
      - Think creatively, imagining a compelling opener you haven’t seen before that fits the topic. Consider questions, intriguing data points, or statements that provoke thought, such as:
          * Unique questions
          * A bold or surprising observation
          * Reflections on industry trends or personal realizations
      - *Examples for inspiration only* (do not copy these): “The real truth behind [topic] might surprise you…”, “Here’s what changed everything for me about [topic]…”

  2. **Core Content**: 
      - Draw on personal experience, industry insights, or concrete examples related to the topic. 
      - Include at least one unique data point, case study, or specific example that reinforces the main message.
      - Focus on insights or actionable advice to help readers learn something valuable or relevant to their own work.

  3. **Structure and Style**:
      - Use concise, 1-2 sentence paragraphs.
      - Space out content with line breaks or bullet points for readability.
      - Avoid hashtags, emojis, buzzwords, or self-promotion.

  4. **Engagement Prompt**:
      - Conclude with a thought-provoking, topic-relevant question designed to encourage discussion, such as: “What strategies have you used with [topic]?” or “How does your team handle [specific challenge]?”

  ### Formatting Outline (for guidance, not as labels):
  - **Opening Hook**
  - **Content with Examples**
  - **Key Insight / Actionable Takeaway**
  - **Engagement Prompt**

  **Requirements**:
  - Keep language professional and direct.
  - Ensure every paragraph adds value and stays aligned with the core topic.
  - Use keywords naturally.

  *Only deliver the final LinkedIn post, formatted as specified.*
  `;

  const prompt = `Generate a LinkedIn post following the provided guidelines and structure. Ensure the post is engaging, authentic, valuable to the audience and written in the specified language ${language}.`;

  try {
    if (isClaudeMode) {
      console.log('Using Claude mode');
      const response = await fetch(CLAUDE_WORKER_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: "claude-3-sonnet-20240229",
          messages: [
            { role: 'user', content: systemMessage + "\n" + prompt }
          ],
          max_tokens: 2500,
          temperature: 0.9,
          stream: true
        }),
      });

      console.log('Claude Response Status:', response.status);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');

      return {
        [Symbol.asyncIterator]: async function* () {
          let buffer = '';
          let fullResponse = '';
          
          while (true) {
            const { done, value } = await reader.read();
            if (done) {
              console.log('Final full response:', fullResponse);
              break;
            }
            
            const chunk = decoder.decode(value, { stream: true });
            console.log('Received chunk:', chunk);
            buffer += chunk;
            fullResponse += chunk;
            
            const lines = buffer.split('\n');
            buffer = lines.pop() || '';
            
            for (const line of lines) {
              if (!line.trim()) continue;
              console.log('Processing line:', line);
              
              if (line.includes('content_block_delta')) {
                const nextLine = lines[lines.indexOf(line) + 1];
                if (nextLine && nextLine.startsWith('data: ')) {
                  try {
                    const data = JSON.parse(nextLine.slice(6));
                    if (data.delta?.text) {
                      // Yield the content in the same format as OpenAI
                      yield { choices: [{ delta: { content: data.delta.text } }] };
                    }
                  } catch (error) {
                    console.warn('Failed to parse data line:', nextLine);
                  }
                }
              }
            }
          }
        }
      };
    } else {
      const requestBody = {
        model: "gpt-4o",
        messages: [
          { role: 'system', content: systemMessage },
          { role: 'user', content: prompt }
        ],
        max_tokens: 2500,
        n: 1,
        temperature: 0.9,
        stream: true,
        presence_penalty: 0.6,
        frequency_penalty: 0.5
      };

      console.log('OpenAI Request:', {
        model: requestBody.model,
        url: OPENAI_API_URL
      });

      const response = await fetch(OPENAI_API_URL, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');

      return {
        [Symbol.asyncIterator]: async function* () {
          let buffer = '';
          
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            
            buffer += decoder.decode(value, { stream: true });
            
            const lines = buffer.split('\n');
            buffer = lines.pop() || '';
            
            for (const line of lines) {
              if (!line.startsWith('data: ')) continue;
              if (line === 'data: [DONE]') return;
              
              try {
                const data = JSON.parse(line.slice(5));
                if (!data.choices?.[0]?.delta?.content) continue;
                
                const content = data.choices[0].delta.content;
                yield { choices: [{ delta: { content } }] };
              } catch (error) {
                console.warn('Failed to parse line:', line);
              }
            }
          }
          
          // Handle any remaining content in buffer
          if (buffer) {
            try {
              const data = JSON.parse(buffer.slice(5));
              if (data.choices?.[0]?.delta?.content) {
                yield { choices: [{ delta: { content: data.choices[0].delta.content } }] };
              }
            } catch (error) {
              console.warn('Failed to parse remaining buffer:', buffer);
            }
          }
        }
      };
    }
  } catch (error) {
    console.error('Error generating LinkedIn post:', error);
    throw error;
  }
};

const refineLinkedInPost = async (businessInfo, originalPost, keywords, language) => {
  const systemMessage = `You are an expert LinkedIn content strategist with over 15 years of experience. Your task is to refine and optimize the given LinkedIn post to enhance engagement, build meaningful connections, and promote authentic conversations. Use the following guidelines and example structures:

1. General Structure:
   - Ensure there's a short, attention-grabbing opening line
   - Use checkmarks (☑) and arrows (↳) for visual appeal, but avoid emojis
   - Incorporate numbered or bulleted lists for easy readability
   - Break content into short paragraphs, often just one sentence each
   - Include questions to engage readers
   - End with a clear call-to-action, encouraging engagement (but don't use the word "repost")

2. Content:
   - Enhance or add personal anecdotes or data to establish credibility
   - Address common questions or objections related to the topic
   - Clarify and emphasize benefits or results
   - Provide or improve step-by-step instructions or explanations if relevant
   - Compare with other tools or methods in the industry if applicable
   - Use or add statistics or social proof to support claims
   - Consider teasing exclusive information or access if appropriate

3. Example Structures (adapt the existing post to one of these if it improves engagement):

   a) Problem-Solution-Results Structure
   b) List-Based Structure
   c) Story-Based Structure
   d) Comparison Structure
   e) Future Prediction Structure

4. Formatting:
   - Use line breaks effectively to separate ideas
   - Incorporate visual elements like "____" for section breaks if needed
   - Highlight key points or quotes for emphasis

5. Refinement Guidelines:
   - Maintain the original intent and key message of the post
   - Improve clarity and conciseness without losing valuable information
   - Enhance the conversational tone while keeping it professional
   - Ensure the post aligns with the provided business information
   - Incorporate the specified keywords naturally throughout the post
   - Adjust the overall structure if it significantly improves readability and engagement

Remember to keep the content authentic, valuable, and shareable. Do not use emojis or hashtags. Choose the most appropriate structure based on the original post and the given business information.`;

  const prompt = `Refine and optimize the following LinkedIn post based on the provided guidelines:

Original Post:
${originalPost}

Business Information: ${businessInfo}
Keywords to Include: ${keywords}
Language: ${language}

Please ensure the refined post aligns with the business information provided and incorporates the keywords naturally. Make the post feel like it was written by a real person, not an AI. Do not use emojis or hashtags. Determine the most appropriate tone, length, and structure for this post based on the example structures provided.`;

  try {
    const response = await axios.post(OPENAI_API_URL, {
      model: "gpt-4o",
      messages: [
        { role: 'system', content: systemMessage },
        { role: 'user', content: prompt }
      ],
      max_tokens: 2500,
      n: 1,
      temperature: 0.7,
    }, {
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        'Content-Type': 'application/json',
      },
    });

    const refinedPostContent = response.data.choices[0].message.content.trim();
    return { refinedPostContent };
  } catch (error) {
    console.error('Error refining LinkedIn post:', error);
    throw error;
  }
};

export { generateLinkedInPost, refineLinkedInPost };
